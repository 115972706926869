import React, { useEffect, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Bundle, Radio } from '../../types/data'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { Option } from '../SelectInput/SelectInput'
import AsyncSelectInput from '../SelectInput/AsyncSelectInput'
import FmWorldAxios from '../../utility/FmWorldAxios'
import { getAvailableRadios, getBundleRadiosDetail } from '../../resources/api-constants'
import LoadingModal from './LoadingModal'
import RadioToAddToBundleTable from '../Tables/RadioToAddToBundleTable'
import RadioFilter, { RadioFilterObject } from '../Filters/RadioFilter'
import { useTranslation } from 'react-i18next'

export interface RadioToAddToBundle {
    id: number
    name: string
    position: number
    weight: number
    isSponsored: boolean
    liveShowed: boolean
}

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: () => void
    bundle: Bundle
    maxPos: number
}

const initialFilter = {
    countries: [],
    categories: [],
    types: [],
    videoOnly: false,
    returnEmptyStreams: true,
    returnOfflineStreams: true,
    networks: [],
    returnVerifiedStreams: false,
}

const AddRadioToBundleModal: React.FC<Props> = (props) => {
    const [radios, setRadios] = useState<RadioToAddToBundle[]>([])
    const [isUpdating, setIsUpdating] = useState(false)
    const [areDuplicates, setAreDuplicates] = useState(false)
    const [error, setError] = useState('')
    const [filter, setFilter] = useState<RadioFilterObject>(initialFilter)
    const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
    const { t } = useTranslation()

    useEffect(() => {
        if (props.isVisible) {
            setRadios([])
        }
    }, [props.isVisible])

    const addRadio = async () => {
        if (radios.length > 0) {
            setError('')
            setIsUpdating(true)
            try {
                for (let i = 0; i < radios.length; i++) {
                    const radio = radios[i]
                    const body = {
                        position: radio.position,
                        weight: radio.weight,
                        isSponsored: radio.isSponsored,
                        liveShowed: radio.liveShowed,
                        fixedPosition: null,
                    }
                    await FmWorldAxios.post(getBundleRadiosDetail(props.bundle.id, radio.id as number), body)
                }
            } catch (error: any) {
                setError(error.toString())
            }
            setIsUpdating(false)
        }
    }

    useEffect(() => {
        const dooped = radios.find((r, index) => radios.find((rf, i) => rf.position === r.position && index !== i))
        if (dooped) {
            setAreDuplicates(true)
        } else {
            setAreDuplicates(false)
        }
    }, [radios])

    const handleFilter = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget)
    }

    const setFilterParams = () => {
        const localFilter: any = {
            videoOnly: filter.videoOnly,
            returnEmptyStreams: filter.returnEmptyStreams,
            returnOfflineStreams: filter.returnOfflineStreams,
            returnVerifiedStreams: filter.returnVerifiedStreams,
        }
        if (filter.countries && filter.countries.length > 0) {
            localFilter.countries = filter.countries.toString()
        }
        if (filter.categories && filter.categories.length > 0) {
            localFilter.categories = filter.categories.toString()
        }
        if (filter.types && filter.types.length > 0) {
            localFilter.types = filter.types.toString()
        }
        if (filter.networks && filter.networks.length > 0) {
            localFilter.networks = filter.networks.toString()
        }
        return localFilter
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', gap: 15 }}>
                    <h2 className="modalBox-title" style={{ flex: 2 }}>
                        {t('networks.add_radio_to_bundle')} {props.bundle.name}
                    </h2>
                    <div style={{ flex: 1 }}>
                        <Button
                            label={t('radios.filter_radio')}
                            onPress={(event) => {
                                handleFilter(event)
                            }}
                            type="submit"
                            background="#fff"
                            border={`2px solid ${
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }`}
                            color={
                                JSON.stringify(filter) !== JSON.stringify(initialFilter) ? COLORS.green : COLORS.purple
                            }
                        />
                        <RadioFilter
                            filter={filter}
                            anchorEl={anchorEl}
                            onClose={() => setAnchorEl(null)}
                            onReset={() => {
                                setFilter(initialFilter)
                            }}
                            onUpdate={(f) => setFilter(f)}
                            position={{
                                vertical: 'bottom',
                                horizontal: 'right',
                            }}
                        />
                    </div>
                </div>

                {error && <p className="u-normal-text u-bold-text u-error-text">{error}</p>}
                {props.bundle ? (
                    <div>
                        <AsyncSelectInput
                            label={t('radios.radio')}
                            value={null}
                            placeholder={t('placeholders.write_to_select_radio')}
                            fetchOptions={async (input: string) => {
                                if (input.length > 2) {
                                    const localFilter = setFilterParams()
                                    const radios = await FmWorldAxios.get(getAvailableRadios(props.bundle.id), {
                                        params: { ...localFilter, query: input },
                                    }).then((res) => {
                                        return res.data.items
                                    })
                                    return radios.map((c: Radio) => {
                                        return {
                                            id: c.id,
                                            value: c.id,
                                            label: c.name,
                                        }
                                    })
                                }
                                return []
                            }}
                            onChange={(value) => {
                                const v = value as Option
                                setRadios([
                                    ...radios,
                                    {
                                        id: v.id as number,
                                        name: v.label,
                                        weight: 1,
                                        position: radios.length + 1,
                                        isSponsored: false,
                                        liveShowed: false,
                                    },
                                ])
                            }}
                        />
                        {radios.length > 0 ? (
                            <RadioToAddToBundleTable
                                radios={radios}
                                maxPos={props.maxPos + radios.length}
                                updatePosition={(p, id) => {
                                    const found = radios.find((r) => r.id === id)
                                    if (found) {
                                        setRadios(
                                            radios.map((r) => {
                                                if (r.id === id) {
                                                    return {
                                                        ...r,
                                                        position: p,
                                                    }
                                                }
                                                return r
                                            })
                                        )
                                    }
                                }}
                                updateSponsor={(s, id) => {
                                    const found = radios.find((r) => r.id === id)
                                    if (found) {
                                        setRadios(
                                            radios.map((r) => {
                                                if (r.id === id) {
                                                    return {
                                                        ...r,
                                                        isSponsored: s,
                                                    }
                                                }
                                                return r
                                            })
                                        )
                                    }
                                }}
                                updateWeight={(w, id) => {
                                    const found = radios.find((r) => r.id === id)
                                    if (found) {
                                        setRadios(
                                            radios.map((r) => {
                                                if (r.id === id) {
                                                    return {
                                                        ...r,
                                                        weight: w,
                                                    }
                                                }
                                                return r
                                            })
                                        )
                                    }
                                }}
                                deletePosition={(id) => {
                                    setRadios(radios.filter((r) => r.id !== id))
                                }}
                            />
                        ) : null}
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={radios.length === 0 || areDuplicates}
                        label={t('general.add')}
                        onPress={async () => {
                            await addRadio()
                            props.onSave()
                        }}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
                <LoadingModal isVisible={isUpdating} text={t('loadings.updating')} />
            </div>
        </Modal>
    )
}

export default AddRadioToBundleModal
