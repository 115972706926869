import React, { useEffect, useRef, useState } from 'react'
import './Modals.sass'
import Modal from '@mui/material/Modal'
import { Bundle } from '../../types/data'
import TextInput from '../TextInput/TextInput'
import Button from '../Button/Button'
import { COLORS } from '../../resources/colors'
import { VoidBundle } from '../../utility/voidConstants'
import CheckboxInput from '../CheckboxInput/CheckboxInput'
import { useTranslation } from 'react-i18next'
import { PLACEHOLDER } from '../../resources/constants'
import SelectInput from '../SelectInput/SelectInput'

interface Props {
    isVisible: boolean
    onClose: () => void
    onSave: (newElement: Bundle | VoidBundle) => void
    bundle: Bundle | VoidBundle | null
}

const BundleModal: React.FC<Props> = (props) => {
    const [bundleToEdit, setBundleToEdit] = useState<Bundle | VoidBundle | null>(props.bundle)
    const { t } = useTranslation()
    const logoRef = useRef<HTMLImageElement>(null)

    useEffect(() => {
        console.log('bundleToEdit', bundleToEdit)
        if (props.isVisible) {
            setBundleToEdit(props.bundle)
        }
    }, [props.isVisible])

    const handleInsertLogo = (e: any, ref: React.RefObject<HTMLImageElement>, onChange: (img: File) => void) => {
        if (e.target.files[0] && bundleToEdit) {
            const image = e.target.files[0]
            if (image) {
                onChange(image)
                const fr = new FileReader()
                fr.onload = function () {
                    if (ref && ref.current && fr.result) {
                        ref.current.src = fr.result as string
                    }
                }
                fr.readAsDataURL(image)
            }
        }
    }

    return (
        <Modal
            open={props.isVisible}
            onClose={() => props.onClose()}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <div className="modalBox actionBox">
                <h2 className="modalBox-title">
                    {bundleToEdit && (bundleToEdit as Bundle).id
                        ? t('networks.edit_bundle')
                        : t('networks.create_bundle')}
                </h2>
                {bundleToEdit ? (
                    <div>
                        <TextInput
                            value={bundleToEdit.name}
                            label={t('general.name')}
                            onTextChange={(e) =>
                                setBundleToEdit({
                                    ...bundleToEdit,
                                    name: e,
                                })
                            }
                        />
                        <div style={{ margin: '20px 0' }}>
                            <TextInput
                                value={bundleToEdit.description}
                                label={t('general.description')}
                                onTextChange={(e) =>
                                    setBundleToEdit({
                                        ...bundleToEdit,
                                        description: e,
                                    })
                                }
                            />
                        </div>
                        <div className="u-elements-row" style={{ marginBottom: 16 }}>
                            <div className="uploadImage">
                                <img
                                    ref={logoRef}
                                    alt="logo1"
                                    src={bundleToEdit.logo ? (bundleToEdit.logo as string) : PLACEHOLDER}
                                    className="uploadImage-logo"
                                    onError={(e: any) => {
                                        e.currentTarget.src = PLACEHOLDER
                                    }}
                                />
                                <input
                                    className="uploadImage-upload"
                                    type="file"
                                    accept="image/*"
                                    onChange={(event) =>
                                        handleInsertLogo(event, logoRef, (img: any) => {
                                            setBundleToEdit({
                                                ...bundleToEdit,
                                                logo: img,
                                            })
                                        })
                                    }
                                />
                            </div>
                            <p className="captionImg">
                                {t('radios.customize_logo_bundle')}*
                                <br />({t('radios.500_500_px')}).
                            </p>
                        </div>
                        <div
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                gap: 16,
                                maxWidth: 260,
                            }}
                        >
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: bundleToEdit.isActive,
                                    },
                                ]}
                                onClick={() => {
                                    setBundleToEdit({
                                        ...bundleToEdit,
                                        isActive: !bundleToEdit.isActive,
                                    })
                                }}
                                label={`${t('general.active')}?`}
                            />
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: bundleToEdit.isGroup,
                                    },
                                ]}
                                onClick={() => {
                                    setBundleToEdit({
                                        ...bundleToEdit,
                                        isGroup: !bundleToEdit.isGroup,
                                    })
                                }}
                                label={`${t('general.group')}?`}
                            />
                            <CheckboxInput
                                values={[
                                    {
                                        label: '',
                                        status: bundleToEdit.isReorderEnabled,
                                    },
                                ]}
                                onClick={() => {
                                    setBundleToEdit({
                                        ...bundleToEdit,
                                        isReorderEnabled: !bundleToEdit.isReorderEnabled,
                                    })
                                }}
                                label={`${t('general.dyncamic_posizioning')}?`}
                            />
                        </div>
                        <SelectInput
                            label={t('general.chart_type')}
                            value={{ id: bundleToEdit.type, value: bundleToEdit.type, label: bundleToEdit.type }}
                            options={[
                                { id: 'audio', value: 'audio', label: 'Audio' },
                                { id: 'video', value: 'video', label: 'Video' },
                            ]}
                            onChange={() => {
                                setBundleToEdit({
                                    ...bundleToEdit,
                                    type: bundleToEdit.type === 'audio' ? 'video' : 'audio',
                                })
                            }}
                        />
                    </div>
                ) : null}
                <div className="modalBox-buttonsRow">
                    <div style={{ flex: '1 1 100%' }} />
                    <Button
                        label={t('general.cancel')}
                        border={'1px solid rgba(0,0,0,0.07)'}
                        onPress={() => props.onClose()}
                        type="secondary"
                        color={COLORS.darkGrey}
                        background="#fff"
                    />
                    <Button
                        disabled={!bundleToEdit || !bundleToEdit?.name}
                        label={bundleToEdit && (bundleToEdit as Bundle).id ? t('general.update') : t('general.add')}
                        onPress={() => props.onSave(bundleToEdit as Bundle)}
                        type="submit"
                        color="#fff"
                        background={COLORS.purple}
                    />
                </div>
            </div>
        </Modal>
    )
}

export default BundleModal
